import React from "react"

import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image, { StaticImageData } from "next/image"
import { createUseStyles } from "react-jss"

import SwitzerTypography from "../../SwitzerTypography"

const useStyles = createUseStyles(() => ({
  reports: {
    backgroundImage:
      "radial-gradient(at 100% 0%, rgba(244, 182, 182, 0.5) 0, transparent 59%),radial-gradient(at 15% 34%, rgba(250, 229, 209, 0.5) 0, transparent 55%)",
  },
  certificates: {
    backgroundImage:
      "radial-gradient(at 100% 0%, rgba(221, 209, 252, 0.5) 0, transparent 59%),radial-gradient(at 15% 34%, rgba(195, 214, 251, 0.5) 0, transparent 55%)",
  },
  masterclasses: {
    backgroundImage:
      "radial-gradient(at 100% 45%, rgba(244, 182, 182, 0.75) 0, transparent 52%),radial-gradient(at 25% 0%, rgba(245, 198, 236, 0.75) 0, transparent 45%)",
  },
  schoolMasterclasses: {
    backgroundImage:
      "radial-gradient(at 5% 23%,rgba(221, 209, 252, 0.75) 0, transparent 44%), radial-gradient(at 82% 51%,rgba(245, 198, 236, 0.75) 0, transparent 56%)",
  },
  schoolCertificates: {
    backgroundImage:
      "radial-gradient(at 20% 46%, rgba(195, 214, 251, 0.75) 0, transparent 59%),  radial-gradient(at 81% 32%, rgba(228, 249, 235, 0.75) 0, transparent 68%)",
  },
  schoolCommunity: {
    backgroundImage:
      "radial-gradient(at 10% -3%, rgba(244, 182, 182, 0.75) 0, transparent 46%), radial-gradient(at 73% 48%, rgba(247, 215, 186, 0.8) 0, transparent 57%)",
  },
}))

type Props = {
  src: StaticImageData
  title: string
  subTitle: string
  cardName:
    | "reports"
    | "certificates"
    | "masterclasses"
    | "schoolMasterclasses"
    | "schoolCertificates"
    | "schoolCommunity"
  className?: string
  variant?: "schoolLanding" | "itoLanding"
}

const Card = ({
  src,
  title,
  subTitle,
  cardName,
  className,
  variant = "itoLanding",
}: Props) => {
  const classes = useStyles()
  const isSchoolLandingPage = variant === "schoolLanding"
  return (
    <div
      className={clsx(
        "w-full  border border-onSurface-200 rounded-2xl overflow-hidden relative bg-white",
        className,
        classes[cardName],
        {
          "h-[460px] p-3": isSchoolLandingPage,
          "lg:h-[420px] h-[370px] pt-5 pl-4": !isSchoolLandingPage,
        }
      )}
    >
      <div className="z-[2] relative">
        {isSchoolLandingPage ? (
          <>
            <SwitzerTypography fontFamily="Switzer Bold" variant="title2">
              {title}
            </SwitzerTypography>
            <Typography className="mt-1">{subTitle}</Typography>
          </>
        ) : (
          <>
            <Typography variant="title2">{title}</Typography>
            <Typography variant="largeBody">{subTitle}</Typography>
          </>
        )}
      </div>

      {/* eslint-disable-next-line @next/next/no-img-element */}
      <Image
        alt={cardName}
        className={clsx("absolute bottom-0 right-0", {
          "h-[280px] object-cover": cardName !== "schoolCommunity",
          "left-0 mx-auto max-h-[261px] h-full object-contain object-bottom px-2":
            cardName === "schoolCommunity",
        })}
        src={src}
      />
    </div>
  )
}

export default Card
// className={clsx("absolute bottom-0 right-0 object-cover", {
//   "h-[280px]": cardName !== "schoolCommunity",
// })}
