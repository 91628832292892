import React from "react"

import { Container } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import useDetectCountry from "utils/hooks/useDetectCountry"

import { itoPageSections } from "../NavigationSection"

import Info from "./Info"
import Syllabus from "./Syllabus"

const useStyles = createUseStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "30% 70%",
    gridTemplateRows: "auto",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "auto auto",
      gridTemplateColumns: "auto",
    },
  },
}))

type Props = { className?: string; isSchool?: boolean }

const SyllabusAndPattern = (props?: Props) => {
  const country = useDetectCountry()
  const classes = useStyles()

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Container className={props?.className}>
      <section
        className={clsx(classes.container)}
        id={itoPageSections.HOW_SYLLABUS}
      >
        <Info country={country} />
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <Syllabus isSchool={props?.isSchool} />
      </section>
    </Container>
  )
}

export default SyllabusAndPattern
