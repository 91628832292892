import React, { useContext } from "react"

import { Container, IconButton, Tooltip, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image, { StaticImageData } from "next/image"
import FacebookSvg from "public/assets/icons/facebook-icon.svg"
import InstagramSvg from "public/assets/icons/instagram-icon.svg"
import LinkedIn from "public/assets/icons/linkedin-icon.svg"
import TwitterSvg from "public/assets/icons/twitter-icon.svg"
import GridSquare from "public/assets/ito/hero-section/grid-square.svg"
import WhatsappSvg from "public/assets/ito/sharing-section/WhatsappSvg"
import { createUseStyles } from "react-jss"

import { Mail } from "iconoir-react"

import { GlobalContext } from "components/GlobalContext"
import { countries } from "components/ito/2022/ContactUs"
import { itoSections } from "utils/constants"

import SwitzerTypography from "../SwitzerTypography"

import Card from "./Card"

const useStyles = createUseStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    gap: "64px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto",
    },
  },
  gradientBackground: {
    backgroundImage: `radial-gradient(at 0% 200%, rgba(105, 219, 123, 0.25) 0, transparent 59%), radial-gradient(at 20% 200%, rgba(235, 150, 77, 0.25) 0, transparent 55%),radial-gradient(at 35% 200%, rgba(235, 72, 63, 0.25) 0, transparent 55%),radial-gradient(at 70% 250%, rgba(234, 89, 202, 0.5) 0, transparent 55%),radial-gradient(at 85% 200%, rgba(52, 109, 242, 0.25) 0, transparent 55%),radial-gradient(at 100% 250%, rgba(116, 63, 245, 0.5) 0, transparent 55%),url(${GridSquare.src})`,
  },
  schoolGradientBackground: {
    backgroundImage: `radial-gradient(at 0% 150%,rgba(82,149,252,0.45)  0, transparent 59%), radial-gradient(at 25% 150%,rgba(82,149,252,0.45)  0, transparent 55%),radial-gradient(at 50% 150%,rgba(52,109,242,0.35)  0, transparent 55%),radial-gradient(at 75% 200%,rgba(188,163,249,0.35)  0, transparent 60%),radial-gradient(at 88% 200%,rgba(172,131,255,0.6) 0, transparent 60%),url(${GridSquare.src})`,
  },
  whiteGradient: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
    [theme.breakpoints.down("xs")]: {
      background:
        "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
    },
  },
}))

type Platforms = "instagram" | "linkedin" | "facebook" | "twitter"
const platformDetails: Record<
  Platforms,
  {
    displayText: string
    url: string
    imgSource: StaticImageData
  }
> = {
  linkedin: {
    displayText: "LinkedIn",
    url: "https://www.linkedin.com/company/international-teachers-olympiad/",
    imgSource: LinkedIn,
  },
  facebook: {
    displayText: "Facebook",
    url: "https://www.facebook.com/teachersolympiad",
    imgSource: FacebookSvg,
  },
  instagram: {
    displayText: "Instagram",
    url: "https://www.instagram.com/teachersolympiad/",
    imgSource: InstagramSvg,
  },
  twitter: {
    displayText: "Twitter",
    url: "https://twitter.com/teacherolympiad",
    imgSource: TwitterSvg,
  },
}

type Props = {
  forSchool?: boolean
  spacing?: string
}

const ContactUs = ({ spacing, forSchool = false }: Props) => {
  const { currentCountry } = useContext(GlobalContext)
  const classes = useStyles()

  const getPhoneNumber = () => {
    if (!currentCountry) return countries.IN

    const phoneNumber =
      countries[currentCountry.isoCode as keyof typeof countries]

    if (!phoneNumber) {
      return countries.IN
    }
    return phoneNumber
  }
  const platforms: Platforms[] = [
    "instagram",
    "facebook",
    "linkedin",
    "twitter",
  ]

  const email = forSchool
    ? "partnerships@teachersolympiad.com"
    : "care@teachersolympiad.com"

  const WhatsAppIcon = (
    <div className="p-1 rounded-full shrink-0 text-ito-mint-700 bg-ito-mint-200">
      <WhatsappSvg />
    </div>
  )
  const MailIcon = (
    <div className="p-1 rounded-full shrink-0 text-ito-lavendar-600 bg-ito-lavendar-100">
      <Mail height={20} width={20} />
    </div>
  )
  return (
    <section
      className={clsx(spacing, "pb-8 mt-3 sm:mt-6 relative", {
        [classes.gradientBackground]: !forSchool,
        [classes.schoolGradientBackground]: forSchool,
      })}
      id={itoSections.CONTACT}
    >
      <div
        className={clsx(classes.whiteGradient, "absolute w-full h-full z-[1]")}
      />

      <Container>
        <div
          className={clsx(
            "md:mb-16 sm:mb-11 mb-6 z-[2] relative",
            classes.container
          )}
        >
          <div className="md:text-start sm:text-center text-start">
            <SwitzerTypography
              className="!text-ito-midnight"
              fontFamily="Switzer Bold"
              variant="title1"
            >
              Contact Us
            </SwitzerTypography>
            <Typography variant="strong">
              Need help? We’re here for you.
            </Typography>
          </div>
          <div className="flex flex-col gap-2 md:flex-col sm:flex-row ">
            {!forSchool && (
              <Card
                body={`+${getPhoneNumber()}`}
                href={`https://wa.me/${getPhoneNumber()}`}
                icon={WhatsAppIcon}
                title="WhatsApp (Instant: 24x7)"
                highlight
              />
            )}

            <Card
              body={email}
              href={`mailto:${email}`}
              icon={MailIcon}
              title="Email"
            />
          </div>
        </div>

        <div className="z-[2] relative">
          <SwitzerTypography
            className="!text-ito-midnight sm:text-center text-start"
            fontFamily="Switzer Bold"
            variant="title1"
          >
            Join the Revolution on Social Media
          </SwitzerTypography>
          <div className="flex flex-wrap justify-center mt-4 mb-3 sm:mb-6">
            {platforms.map(platform => (
              <Tooltip
                color="light"
                direction="bottom"
                key={platform}
                title={platformDetails[platform].displayText}
              >
                <IconButton
                  className="!rounded-full border border-onSurface-300 mr-3 mb-2 !h-7 !w-7 bg-white p-1.5"
                  onClick={() => window.open(platformDetails[platform].url)}
                >
                  <Image
                    alt={platform}
                    src={platformDetails[platform].imgSource}
                  />
                </IconButton>
              </Tooltip>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ContactUs
