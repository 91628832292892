import React from "react"

import { Container } from "@suraasa/placebo-ui"

import { RatingAndReviewId } from "utils/constants"

import SwitzerTypography from "../SwitzerTypography"

import Card from "./Card"

export const itoPageSections = {
  SYLLABUS: "Syllabus",
  PRIZE: "Prize",
  WHAT_YOU_GET_WHAT_YOU_PAY: "WhatYouGetWhatYouPay",
  HOW_SYLLABUS: "Syllabus",
  SCHOOL_LEADER_TESTIMONIAL: "SchoolLeaderTestimonial",
  FEATURED_IN: "FeaturedIn",
  TESTIMONIAL: "Testimonial",
  GROWTH: "Growth",
  FAQ: "FAQ",
}
const pageRoute: {
  text: string
  link: string
  variant: "peach" | "bubblegum" | "lavender" | "salmon" | "mint" | "iris"
}[] = [
  {
    text: "What is the Syllabus?",
    link: itoPageSections.SYLLABUS,
    variant: "peach",
  },
  {
    text: "What are the Prizes?",
    link: itoPageSections.PRIZE,
    variant: "lavender",
  },
  {
    text: "Is it worth the Investment?",
    link: itoPageSections.WHAT_YOU_GET_WHAT_YOU_PAY,
    variant: "iris",
  },
  {
    text: "How do I attempt the Olympiad?",
    link: itoPageSections.FAQ,
    variant: "mint",
  },
  {
    text: "Do Schools Leaders Recommend it?",
    link: itoPageSections.SCHOOL_LEADER_TESTIMONIAL,
    variant: "bubblegum",
  },
  {
    text: "How do I trust it?",
    link: RatingAndReviewId,
    variant: "salmon",
  },
  {
    text: "What happens after the Olympiad?",
    link: itoPageSections.GROWTH,
    variant: "peach",
  },
  {
    text: "Can I see some Reviews?",
    link: itoPageSections.TESTIMONIAL,
    variant: "lavender",
  },
]

const NavigationSection = ({ className }: { className?: string }) => (
  <Container className={className}>
    <div>
      <SwitzerTypography
        className="mb-5"
        fontFamily="Switzer Bold"
        variant="title1"
      >
        Curious to Know More?
      </SwitzerTypography>
      <div className="grid grid-cols-2 grid-rows-4 gap-2 sm:grid-cols-3 sm:grid-rows-3 md:grid-rows-2 md:grid-cols-4 sm:gap-3">
        {pageRoute.map((data, index) => (
          <Card
            href={data.link}
            key={index}
            title={data.text}
            variant={data.variant}
          />
        ))}
      </div>
    </div>
  </Container>
)

export default NavigationSection
