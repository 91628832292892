import React, { useEffect } from "react"

import Glide from "@glidejs/glide"
import { Theme } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"

import { NavArrowLeft, NavArrowRight } from "iconoir-react"

import ReviewCard from "../RatingAndReviews/ReviewCard"
import { TestimonialType } from "../RatingAndReviews/testimonials"

import TestimonialCard, { Testimony } from "./TestimonialCard"

const useStyles = createUseStyles(theme => ({
  gradientBg: {
    background:
      "linear-gradient(77deg, rgba(249,217,242,0.5) 0%, rgba(195,214,251,0.5) 100%)",
  },
  title: {
    fontFamily: "Switzer Bold",
    fontSize: 32,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.64px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
      letterSpacing: "-0.56px",
    },
  },
  whiteGradient: {
    background:
      "linear-gradient(180deg, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 100%)",
  },
}))

const AboutReports = ({
  title,
  data,
  itoLandingData,
}: {
  title: string
  data?: Testimony[]
  itoLandingData?: TestimonialType[]
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  useEffect(() => {
    const glide = new Glide("#reportSlider", {
      type: "slider",
      gap: 8,
      perView: 3,
      rewind: true,
      focusAt: 0,
      bound: true,
      peek: { before: 0, after: 0 },
      breakpoints: {
        [theme.breakpoints.values.lg]: {
          perView: 3,
        },
        [theme.breakpoints.values.md]: {
          perView: 2,
        },
        [theme.breakpoints.values.sm]: {
          perView: 1,
        },
      },
    }).mount()

    return () => {
      glide.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="relative overflow-y-hidden sm:overflow-visible rounded-2xl">
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
     .glide__bullet{
      width: 6px;
      height: 6px;
      border-radius: 999px;
      background: #CBD5E1;
     }
     .glide__bullet--active{
      background: #64748B;
     }
        `,
        }}
      />
      <div
        className={clsx(
          classes.whiteGradient,
          "w-full h-full left-0 absolute z-[1] top-0"
        )}
      />
      <div
        className={clsx(
          classes.gradientBg,
          "absolute w-full h-full rounded-2xl md:bottom-[20%] sm:bottom-[30%] bottom-0"
        )}
      />
      <span
        className={clsx(
          classes.title,
          "px-3 block sm:text-center text-start text-ito-midnight z-[2] relative md:mb-6 mb-4"
        )}
      >
        {title}
      </span>
      <div className="sm:px-3 px-2 pb-6 sm:p-0 z-[2] relative">
        <div className="relative w-full" id="reportSlider">
          <div
            className="w-full overflow-hidden glide__track"
            data-glide-el="track"
          >
            <div
              className="flex glide__slides"
              style={{ listStyleType: "none" }}
            >
              {itoLandingData &&
                itoLandingData.map((item, index) => (
                  <ReviewCard
                    className="w-full my-2"
                    data={item}
                    id={index}
                    key={index}
                    isAboutReport
                  />
                ))}
              {data &&
                data.map((item, index) => (
                  <TestimonialCard item={item} key={index} />
                ))}
            </div>
          </div>

          <div
            className="slider__bullets glide__bullets dotSliderContainer z-[2] absolute inline-flex sm:hidden -bottom-3 w-full gap-1 items-center justify-center"
            data-glide-el="controls[nav]"
            style={{
              listStyle: "none",
            }}
          >
            {data &&
              data.map((_, index) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                  className="slider__bullet glide__bullet glide__bullet--active"
                  data-glide-dir={`=${index}`}
                  key={index}
                />
              ))}
            {itoLandingData &&
              itoLandingData.map((_, index) => (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <button
                  className="slider__bullet glide__bullet glide__bullet--active"
                  data-glide-dir={`=${index}`}
                  key={index}
                />
              ))}
          </div>

          <div
            className="absolute -left-7 top-[50%] md:block hidden"
            data-glide-el="controls"
          >
            <button
              className="w-3.5 h-3.5 rounded-full bg-surface-100 flex items-center justify-center"
              data-glide-dir="<"
              id="left"
            >
              <NavArrowLeft />
            </button>
          </div>

          <div
            className="absolute -right-7 top-[50%] md:block hidden"
            data-glide-el="controls"
          >
            <button
              className="w-3.5 h-3.5 rounded-full bg-surface-100 flex items-center justify-center"
              data-glide-dir=">"
              id="right"
            >
              <NavArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutReports
